<template>
    <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
        <div>
            <RouterLink class="w-fit" to="/">
                <CommonLogo class="h-8 w-fit" />
            </RouterLink>
            <h2 class="mt-5 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">Create an account
            </h2>
        </div>

        <div>
            <div class="relative flex flex-wrap w-full h-full">
                <div class="relative w-full max-w-sm lg:mb-0">
                    <div class="relative">
                        <div class="flex flex-col mb-6 space-y-2">
                            <p class="text-sm text-neutral-500">Enter your email below to create your account</p>
                        </div>
                        <form @submit="onSubmit" class="space-y-2">
                            <CommonInput label="Email" placeholder="name@example.com" name="email" />
                            <div class="py-2 flex flex-col gap-2">
                                <CommonInput label="Password" placeholder="Password" name="password" type="password" />
                                <CommonInput label="Password confirm" placeholder="Confirm Password"
                                    name="password_confirmation" type="password" />
                            </div>

                            <UButton :loading="isSubmitting" type="submit" block trailing>Create account</UButton>

                            <!-- todo enable -->
                            <!-- <div class="relative py-6">
                                <div class="absolute inset-0 flex items-center"><span
                                        class="w-full border-t"></span></div>
                                <div class="relative flex justify-center text-xs uppercase">
                                    <span class="px-2 bg-white text-neutral-500">Or continue with</span>
                                </div>
                            </div>
                            <SocialAuth /> -->
                        </form>
                    </div>
                    <p class="mt-6 text-sm text-left text-neutral-500">
                        Already have an account?
                        <NuxtLink prefetch to="/auth/sign-in" class="relative font-medium text-blue-600 group">
                            <span>Login here</span>
                            <span
                                class="absolute bottom-0 left-0 w-0 group-hover:w-full ease-out duration-300 h-0.5 bg-blue-600">
                            </span>
                        </NuxtLink>
                    </p>
                    <p class="mt-2 text-sm text-left text-neutral-500">By continuing, you agree to our <NuxtLink
                            prefetch class="underline underline-offset-4 hover:text-primary" to="/terms">
                            Terms
                        </NuxtLink> and <NuxtLink prefetch class="underline underline-offset-4 hover:text-primary"
                            to="/privacy">Policy</NuxtLink>.</p>
                </div>
            </div>
        </div>
    </UCard>
</template>

<script setup lang="ts">
import ApiError from "~/api/models/ApiError";


interface Credentials {
    email: string;
    password: string;
    password_confirmation: string;
}

const credentials: Credentials = reactive({
    email: "",
    password: "",
    password_confirmation: "",
});

const { register } = useAuth();

const { handleSubmit, meta, values, isSubmitting, setErrors, errors } = useForm(
    {
        initialValues: credentials,
    }
);

const error = ref<string>("");

const toast = useToast()

const onSubmit = handleSubmit(async (values) => {
    try {
        await register(values.email, values.password, values.password_confirmation);
        closeSignUpDialog();
    } catch (apiError) {
        if (apiError instanceof ApiError) {
            setErrors(apiError?.errors);
            error.value = apiError.message;
        }
    }
});
</script>

<style scoped></style>
